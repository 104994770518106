import { useRef } from "react";
import { fetchJSON } from "../../dataProvider";
import sleep from "../../common/util/sleep";

const useProdutoValidateCod = (record, lojaId, field) => {
  const counter = useRef(0);
  const lastValue = useRef(record[field]);
  const lastResp = useRef(undefined);

  const uniq = async (value, allValues) => {
    counter.current++;
    const myCall = counter.current;

    await sleep(750);

    if (myCall < counter.current) {
      return "Aguarde....";
    }

    if (lastValue.current === value) {
      return lastResp.current;
    }

    lastValue.current = value;

    // if (field === "cod_aux") {
    if (value === null || value === undefined) {
      return undefined;
    } else if (value.toString().includes(" ")) {
      return "Remova os espaços";
    }
    // }

    if (
      allValues.cod_prod &&
      allValues.cod_prod.toString() === allValues.cod_aux
    ) {
      return "Os códigos devem ser diferentes";
    }

    return fetchJSON("produtos/uniqCodigo", null, {
      lojaId,
      value,
      id: allValues.id,
    }).then((count) => {
      const error = count > 0 ? "Código já utilizado" : undefined;
      lastResp.current = error;
      return error;
    });
  };

  return { uniq };
};

export default useProdutoValidateCod;
