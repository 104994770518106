import React, { useState } from "react";
import ClearIcon from "@material-ui/icons/Clear";
import LoadingButton from "../../common/LoadingButton";
import { fetchJSON } from "../../dataProvider";
import { HttpError, useDataProvider, useNotify } from "react-admin";

const NFCeCancelarButton = ({ record }) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [loading, setLoading] = useState(false);

  if (!record.xmlUrl) {
    return null;
  }

  if (record.xmlCancelamentoUrl) {
    return null;
  }

  const cancelarNFCe = async () => {
    try {
      setLoading(true);
      const nfce = await fetchJSON("Sefaz/cancelaNFCe", {
        method: "POST",
        body: {
          lojaId: record.lojaId,
          movimentoId: record.movimentoId,
        },
      });

      await dataProvider.update("NFCes", {
        onlyUpdateLocalStore: true,
        data: {
          ...nfce,
        },
      });
    } catch (err) {
      console.log(JSON.stringify(err));

      if (err instanceof HttpError) {
        if (err.status === 400) {
          notify(err.message);
          return;
        }
      }

      notify("Ocorreu um erro ao cancelar a NFCe");
    } finally {
      setLoading(false);
    }
  };

  return (
    <LoadingButton
      variant="contained"
      color="error"
      text="Cancelar"
      loading={loading}
      icon={<ClearIcon />}
      onClick={cancelarNFCe}
    />
  );
};

export default NFCeCancelarButton;
