import React, { useState } from "react";
import { useDataProvider, useNotify } from "react-admin";
import ClearIcon from "@material-ui/icons/Clear";
import LoadingButton from "../../common/LoadingButton";
import { fetchJSON } from "../../dataProvider";

const ConnectStoneCancelarButton = ({ record }) => {
  const dataProvider = useDataProvider();
  const [loading, setLoading] = useState(false);
  const notify = useNotify();

  if (!record) return null;

  const handleCancelar = async () => {
    try {
      setLoading(true);
      const canceled = await fetchJSON("ConnectStone/cancelar", {
        method: "POST",
        body: JSON.stringify({
          lojaId: record.lojaId,
          connectStoneId: record.id,
        }),
      });

      await dataProvider.update("ConnectStone", {
        onlyUpdateLocalStore: true,
        data: {
          ...record,
          ...canceled,
        },
      });
    } catch (err) {
      notify("Erro cancelar pagamento do Stone Connect", "warning");
      console.log(JSON.stringify(err));
    } finally {
      setLoading(false);
    }
  };

  return (
    <LoadingButton
      text="Cancelar"
      onClick={handleCancelar}
      loading={loading}
      icon={<ClearIcon />}
    />
  );
};

export default ConnectStoneCancelarButton;
