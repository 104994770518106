import React from "react";
import { NumberInput, Filter } from "react-admin";
import QuickFilter from "../../common/form/QuickFilter";

export const nfce_filters = {
  nfce_autorizada: {
    xmlUrl: { neq: [null] },
    xmlCancelamentoUrl: null,
  },
  nfce_cancelada: {
    xmlCancelamentoUrl: { neq: [null] },
  },
  nfce_pendente: {
    xmlUrl: null,
    cancelada: false,
  },
};

const MovimentoFilter = (props) => (
  <Filter {...props}>
    <NumberInput source="serie" label="Série" alwaysOn />
    <NumberInput source="nNF" label="Número" alwaysOn />
    <QuickFilter
      source="nfce_autorizada"
      label="Autorizada"
      defaultValue={true}
    />
    <QuickFilter source="nfce_pendente" label="Pendente" defaultValue={true} />
    <QuickFilter
      source="nfce_cancelada"
      label="Cancelada"
      defaultValue={true}
    />
  </Filter>
);

export default MovimentoFilter;
