export function formatCurrency(value = 0, decimalsMin = 2, decimalsMax = 2) {
  return `R$ ${formatNumber(value, decimalsMin, decimalsMax)}`;
}

export function formatNumberNoDecimals(value = 0, decimals = 2) {
  return value % 1 === 0
    ? formatNumber(value, 0)
    : formatNumber(value, decimals);
}

export function formatNumber(value = 0, decimalsMin = 2, decimalsMax = 2) {
  return Number(value).toLocaleString("pt-BR", {
    style: "decimal",
    minimumFractionDigits: decimalsMin,
    maximumFractionDigits: decimalsMax,
  });
}

export function formatCNPJ(value) {
  return value.replace(
    /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
    "$1.$2.$3/$4-$5"
  );
}

export function formatCPF(value) {
  return value.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
}

export function formatCNPJCPF(value) {
  return value.length < 14 ? formatCPF(value) : formatCNPJ(value);
}

export function formatPhone(phone) {
  if (!phone) return "";

  if (phone.length > 10) {
    return phone.replace(/^(\d{2})(\d{5})(\d*)/, "($1) $2 - $3");
  }

  return phone.replace(/^(\d{2})(\d{4})(\d*)/, "($1) $2 - $3");
}

export function formatDateTime(dateTime) {
  return new Date(dateTime).toLocaleString("pt-BR");
}

export function formatDateTimeShort(dateTime) {
  return new Date(dateTime).toLocaleString("pt-BR", {
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  });
}

export function formatDate(dateTime) {
  return new Date(dateTime).toLocaleString("pt-BR", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  });
}
