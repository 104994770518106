import { S3, PutObjectCommand, DeleteObjectCommand } from "@aws-sdk/client-s3";
import { v4 as uuidv4 } from "uuid";
import Resizer from "react-image-file-resizer";
import { FOTO_PREFIX } from "../config/constants";

const url = process.env.REACT_APP_S3_PALLAS_CLOUD_URL;
const s3 = new S3({
  endpoint: process.env.REACT_APP_S3_ENDPOINT,
  region: "us-east-1",
  credentials: {
    accessKeyId: process.env.REACT_APP_S3_KEY_ID,
    secretAccessKey: process.env.REACT_APP_S3_ACCESS_KEY,
  },
  signatureVersion: "v4",
});

const resizeFile = (file) =>
  new Promise((resolve) => {
    const format = file.name.toUpperCase().endsWith(".PNG") ? "PNG" : "JPEG";
    Resizer.imageFileResizer(
      file,
      1280,
      720,
      format,
      70,
      0,
      (newFile) => resolve(newFile),
      "blob"
    );
  });

const resizeFileConditional = async (file, key) => {
  if (key.includes("/autoatendimento_config/")) {
    const arrayBuffer = await file.arrayBuffer();
    return new Blob([Buffer.from(arrayBuffer)], { type: file.type });
  }

  return resizeFile(file);
};

const uploadImages = async (resource, data) => {
  const fotos = Object.keys(data)
    .filter((key) => key.startsWith(FOTO_PREFIX))
    .filter((key) => data[key].rawFile instanceof File);

  if (fotos.length === 0) {
    return;
  }

  const dataToUpload = fotos.map((foto) => {
    const field = foto.replace(FOTO_PREFIX, "");
    const currentUrl = data[field];
    const rawFile = data[foto].rawFile;

    delete data[foto];
    deleteImage(currentUrl);

    const fileName = rawFile.name;
    const key = `${data.lojaId}/${resource}/${uuidv4()}/${fileName}`;
    data[field] = url + key;

    return {
      rawFile,
      key,
    };
  });

  const promises = dataToUpload.map(async ({ rawFile, key }) => {
    const blob = await resizeFileConditional(rawFile, key);

    const params = {
      Bucket: "pallas-nuvem",
      Body: blob,
      Key: key,
      ACL: "public-read",
      ContentType: blob.type,
      ContentLength: blob.size,
    };

    return await s3.send(new PutObjectCommand(params));
  });

  return await Promise.all(promises);
};

const deleteImage = async (imageUrl) => {
  if (!imageUrl || !imageUrl.includes(url)) {
    return;
  }

  const key = imageUrl.replace(url, "");
  const params = {
    Bucket: "pallas-nuvem",
    Key: key,
  };

  await s3.send(new DeleteObjectCommand(params));
};

const deleteImages = async (data) => {
  const fotos = Object.keys(data).filter((key) => key.startsWith(FOTO_PREFIX));

  if (fotos.length === 0) {
    return;
  }

  const promises = fotos.map(async (foto) => {
    const field = foto.replace(FOTO_PREFIX, "");
    const fieldUrl = data[field];
    return deleteImage(fieldUrl);
  });

  return await Promise.allSettled(promises);
};

export { s3, uploadImages, deleteImages };
