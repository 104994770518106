import React from "react";
import {
  SimpleForm,
  TextField,
  TextInput,
  NumberInput,
  BooleanField,
  ReferenceManyField,
  Datagrid,
  FormDataConsumer,
  Labeled,
  BooleanInput,
} from "react-admin";
import {
  IdDates,
  CreatedFieldNL,
  UpdatedFieldNL,
} from "../../common/form/ConditionalInputs";
import { TotemOperacaoInput } from "./TotemOperacaoInput";
import { Box } from "@mui/material";
import { withUser } from "../../common/util/hocs";
import { QRCodeCanvas } from "qrcode.react";

const EquipamentoForm = (props) => {
  const { record, loja } = props;

  return (
    <SimpleForm {...props} redirect="list">
      <IdDates />
      {record.androidId && <TextField source="androidId" label="Android ID" />}
      {record.versao && <TextField source="versao" label="Versão do App" />}
      {record.hardware && (
        <TextField source="hardware" label="Android Hardware" />
      )}

      <BooleanInput source="ativo" label="Ativo" />
      <TextInput source="nome" label="Nome do equipamento" />
      <NumberInput source="nfce_serie" label="NFCe série" />
      <NumberInput source="nfce_nNF" label="NFCe última nota emitida" />
      <TotemOperacaoInput
        source="totem_operacao"
        label="Modo de operação do POS"
      />

      <FormDataConsumer>
        {({ formData }) => {
          const urlBase = `https://autoatendimento.sistemapallas.com.br/${loja.appDeliveryUri}/autoatendimento`;
          const urlCardapioDigital =
            formData.totem_operacao === "AGUARDA_TRANSACAO"
              ? `${urlBase}/totem-${formData.id}-${formData.androidId}`
              : formData.totem_operacao === "NUMERO_PEDIDO"
              ? `${urlBase}/qrcode`
              : null;

          if (!urlCardapioDigital) return null;

          const appUrl = encodeURI(urlCardapioDigital);

          return (
            <Box display="grid" sx={{ mb: 5 }}>
              <Labeled label="Link para realizar os pedidos">
                <a href={appUrl} target="_blank" rel="noopener noreferrer">
                  {appUrl}
                </a>
              </Labeled>
              <QRCodeCanvas value={appUrl} />
            </Box>
          );
        }}
      </FormDataConsumer>

      {!record.androidId && (
        <ReferenceManyField
          addLabel={true}
          label="Lista de eventos"
          reference="equipamento_eventos"
          perPage={5}
          sort={{ field: "id", order: "DESC" }}
          target="equipamentoId"
          fullWidth
        >
          <Datagrid>
            <TextField source="id" />
            <CreatedFieldNL label="Atualizar até" />
            <UpdatedFieldNL label="Completo em" />
            <BooleanField source="completo" />
          </Datagrid>
        </ReferenceManyField>
      )}
    </SimpleForm>
  );
};

export default withUser(EquipamentoForm);
